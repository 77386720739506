import React from 'react'
import PropTypes from 'prop-types'
import config from 'my-account-config'
import { Link } from 'react-router-dom'
import { DateTime } from 'luxon'

import CircularProgress from '@mui/material/CircularProgress'

import SinglePaymentBankSelector from './single-payment-bank-selector'
import PaymentInput from './payment_fields/payment-input'
import DateInput from './common/date-input'
import SelectorInput from '../common_formik/selector-input'
import { getShareAmountList } from '../../constants/payment/share-amount-list'
import AmountDueAndDueDateLabel from './common/amount-due-and-due-date-label'

class SinglePaymentSelectBankForm extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        let supportPhoneNumber = this.props.isResidential
            ? this.props.supportPhoneNumbers.residentialCustomerServicePhoneNumber
            : this.props.supportPhoneNumbers.commercialCustomerServicePhoneNumber

        let alternateFormikProps = {
            errors: this.props.formikProps.errors,
            touched: this.props.formikProps.touched
        }
        let verifyStatus = this.props.formikProps.status || {}
        let onPaymentAmountChange = values => {
            let value = values.value
            this.props.formikProps.setFieldValue('paymentAmount', value)
            this.props.getPaymentAmountWarning(value)
            this.props.formDataChanged(values)
        }
        let onPaymentDateChange = date => {
            let value = DateTime.fromISO(date).startOf('day').toISODate()
            this.props.formikProps.setFieldValue('paymentDate', value)
            this.props.getPaymentDateWarning(value)
            this.props.isPaymentDateWithinTwoBusinessDaysOfSurePayRelease(value)
            this.props.formDataChanged(date)
        }
        let onShareChange = e => {
            let value = Math.trunc(e.target.value)
            this.props.formikProps.setFieldValue('shareAmount', value)
            this.props.formDataChanged(e)
        }

        const onBankAccountChanged = (bankAccountId) => {
            this.props.formDataChanged(bankAccountId)
        }

        const isSpanish = this.props.i18n.language === "es"

        return (
            <form onSubmit={this.props.formikProps.handleSubmit}>
                {verifyStatus.hasUnandledPaymentError
                    ? <div className="srp-alert-error mt-3 pt-2 mb-1" id="unhandledPaymentError">
                        <span className="d-lg-block d-none">{this.props.t("Unable_complete_payment")} {supportPhoneNumber}.</span>
                        <span className="d-lg-none d-block">{this.props.t("Unable_complete_payment")} <a href={"tel:" + supportPhoneNumber}>{supportPhoneNumber}</a>.</span>
                    </div>
                    : <span />}
                <label className="h4 text-muted pt-2">{this.props.t("Bank account")}</label>
                <SinglePaymentBankSelector
                    onAddBankAccountClick={this.props.onAddBankAccountClick}
                    onBankAccountChanged={onBankAccountChanged}
                    t={this.props.t}
                />
                <label className="h4 text-muted pt-4">{this.props.t("Payment amount & date")}</label>
                <AmountDueAndDueDateLabel
                    amountDue={this.props.paymentDetails.amountDueWithShare}
                    dueDate={this.props.dueDate}
                    isValid={this.props.isPaymentInfoValid}
                    pastDue={this.props.paymentDetails.pastDue}
                    summaryBillingStatus={this.props.summaryBillingStatus}
                    isSurePay={this.props.paymentDetails.isSurePay}
                    t={this.props.t}
                />
                <div className="mt-1">
                    <PaymentInput
                        {...alternateFormikProps}
                        id="paymentAmount"
                        label="Payment amount"
                        value={this.props.formikProps.values.paymentAmount}
                        onValueChange={onPaymentAmountChange}
                        setFieldValue={this.props.formikProps.setFieldValue}
                        t={this.props.t}
                    />
                </div>
                <div className="mt-3">
                    <DateInput
                        {...alternateFormikProps}
                        id="paymentDate"
                        label={this.props.t("Payment date")}
                        value={this.props.formikProps.values.paymentDate}
                        onChange={onPaymentDateChange}
                        onBlur={() => this.props.formikProps.setFieldTouched('paymentDate', true)}
                        dateProps={this.props.dateFieldProps}
                        i18n={this.props.i18n}
                    />
                </div>
                {(this.props.hasPaymentOverAmountWarning
                    || this.props.hasPaymentUnderAmountWarning
                    || this.props.hasPaymentDateWarning
                    || this.props.hasSurePayWarning) &&
                    <div className="srp-alert-warn mt-3 pt-2 mb-1">
                        <strong>{this.props.t("ATTENTION")}</strong>&nbsp;&nbsp;
                                    {this.props.getPaymentWarningText()}
                        {(this.props.hasPaymentDateWarning || this.props.hasPaymentUnderAmountWarning) && this.props.creditExtensionInfo.isEligible &&
                            <span> {this.props.t("Need")} <Link to="/myaccount/dashboard" onClick={this.props.startCIXFlowOnClick}>{this.props.t("more time to pay")}</Link>? {this.props.t("We may be able to assist you.")}</span>
                        }
                        {this.props.showPaymentDateWithinTwoBusinessDaysOfSurePayReleaseWarning &&
                            <div>
                                <br />
                                <strong>{this.props.t("NOTE")}</strong>&nbsp;&nbsp;{this.props.t("Surepay_pending_payment")}
                                {this.props.t("Surepay_may_not_post")}&nbsp;{supportPhoneNumber}.
                                    </div>
                        }
                    </div>
                }
                {this.props.paymentDetails.shareOptionAmount === 0 &&
                    <div className="mt-3">
                        <p className="text-muted">
                            {this.props.t("Want to help fellow Arizonans through")+" "}
                            <a className="displayAsLink" href={isSpanish
                                ? `${config.srpnetBaseUrlEs}cuenta/donacion/ayudando-vecinos`
                                : `${config.srpnetBaseUrl}account/donate/help-neighbors`} target="_blank">{this.props.t("SHARE")}</a>
                            {`? ${this.props.t("Donate $1, $2 or $5 and SRP will match your contribution.")}`}
                        </p>
                        <SelectorInput
                            id="shareAmount"
                            label={this.props.t("SHARE donation amount")}
                            value={this.props.formikProps.values.shareAmount.toString()}
                            optionList={getShareAmountList(this.props.t)}
                            onChange={onShareChange}
                            onBlur={() => this.props.formikProps.setFieldTouched('shareAmount', true)}
                            displayEmpty={true}
                            {...alternateFormikProps}
                        />
                    </div>
                }
                <input
                    id="srpAccountNumber"
                    name="srpAccountNumber"
                    type="hidden"
                    value={this.props.formikProps.values.srpAccountNumber}
                    {...alternateFormikProps} />
                <div className="d-flex justify-content-end mt-4">
                    <button className="btn srp-btn btn-blue" type="submit" disabled={this.props.formikProps.isSubmitting}>
                        {this.props.formikProps.isSubmitting ? <CircularProgress size={20} thickness={5} style={{ color: 'white' }} /> : this.props.t("Confirm payment")}
                    </button>
                </div>
            </form>
        )
    }
}

SinglePaymentSelectBankForm.propTypes = {
    formikProps: PropTypes.object,
    supportPhoneNumbers: PropTypes.shape({
        residentialCustomerServicePhoneNumber: PropTypes.string.isRequired,
        residentialCustomerServicePhoneNumberSpanish: PropTypes.string.isRequired,
        commercialCustomerServicePhoneNumber: PropTypes.string.isRequired
    }).isRequired,
    paymentDetails: PropTypes.object.isRequired,
    isPaymentInfoValid: PropTypes.bool.isRequired,
    onAddBankAccountClick: PropTypes.func.isRequired,
    startCIXFlowOnClick: PropTypes.func,
    isResidential: PropTypes.bool.isRequired,
    summaryBillingStatus: PropTypes.number.isRequired,
    creditExtensionInfo: PropTypes.object.isRequired,
    dateFieldProps: PropTypes.object,
    hasPaymentOverAmountWarning: PropTypes.bool,
    hasPaymentUnderAmountWarning: PropTypes.bool,
    hasPaymentDateWarning: PropTypes.bool,
    hasSurePayWarning: PropTypes.bool,
    showPaymentDateWithinTwoBusinessDaysOfSurePayReleaseWarning: PropTypes.bool,
    dueDate: PropTypes.string,
    getPaymentDateWarning: PropTypes.func,
    getPaymentAmountWarning: PropTypes.func,
    isPaymentDateWithinTwoBusinessDaysOfSurePayRelease: PropTypes.func,
    getPaymentWarningText: PropTypes.func,
    formDataChanged: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
}

export default SinglePaymentSelectBankForm
