import React from "react"
import PropTypes from 'prop-types'
import Card from "@mui/material/Card"
import CircularProgress from "@mui/material/CircularProgress"
import config from 'my-account-config'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import MakeCreditCardPaymentLink from './make-a-credit-card-payment-link'
import { getBillingAddress } from '../../actions/auth/bill_account/bill-account-actions'
import { getContacts } from '../../actions/auth/contact/contact-actions'
import { LOADING_STATUS_INIT, LOADING_STATUS_IN_PROGRESS, LOADING_STATUS_SUCCESS } from '../../constants/loading-status-constants'
import { getCreditCardMultiPaymentUrl } from '../../actions/auth/payment/payment-actions'

let hasLoaded = (props) => {
    let loaded = true

    if (props.billAccountListStatus === LOADING_STATUS_INIT ||
        props.billAccountListStatus === LOADING_STATUS_IN_PROGRESS ||
        props.billingAddressStatus === LOADING_STATUS_INIT ||
        props.billingAddressStatus === LOADING_STATUS_IN_PROGRESS ||
        props.rateMetaDataStatus === LOADING_STATUS_INIT ||
        props.rateMetaDataStatus === LOADING_STATUS_IN_PROGRESS ||
        props.contactsStatus === LOADING_STATUS_INIT ||
        props.contactsStatus === LOADING_STATUS_IN_PROGRESS ||
        props.serviceAddressStatus === LOADING_STATUS_INIT || // Service address is unused by the credit card link, so this might be an obsolete check now
        props.serviceAddressStatus === LOADING_STATUS_IN_PROGRESS)
    {
        loaded = false
    } else if (props.billAccountListStatus === LOADING_STATUS_SUCCESS) {
        if (props.billAccountList.length > 0 && props.multiPayment && (props.paymentInfoListStatus === LOADING_STATUS_INIT || props.paymentInfoListStatus === LOADING_STATUS_IN_PROGRESS )) {
            loaded = false
        }
    }

    return loaded
}

let hasLoadedCreditCardPaymentUrl = (props) => {
    let loaded = true

    if (props.creditCardPaymentUrlStatus === LOADING_STATUS_INIT ||
        props.creditCardPaymentUrlStatus === LOADING_STATUS_IN_PROGRESS) {
            loaded = false
        }

    return loaded
}

let updateDataIfNeeded = (props) => {
    // billAccountList and rateMetaData are required for the general header, so they don't need to be explicitly loaded. They will eventually be loaded by the other common components

    // PaymentInfo list will be loaded by the multipayment page

    if (props.selectedBillAccount === 0)
        return

    if (props.contactsStatus === LOADING_STATUS_INIT) {
        props.getContacts(props.selectedBillAccount)
    }

    if (props.billingAddressStatus === LOADING_STATUS_INIT) {
        props.getMailingAddress(props.selectedBillAccount)
    }
}

class OtherPaymentOptions extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            hasMpower: false,
            showGiftPayment: this.props.showGiftPayment || true,
            showPayCenterLink: this.props.showPayCenterLink || false,
            showCreditDebit: this.props.showCreditDebit || false,
            showAutoPay: this.props.showAutoPay || false,
            multiPayment: this.props.multiPayment || false
        }
    }

    componentDidMount(){
        if (hasLoaded(this.props)) {
            this.props.getCreditCardPaymentLink()

            /* eslint react/no-did-mount-set-state: "off" */
            /* It's ok to call setState in componentDidMount. See https://reactjs.org/docs/react-component.html . */
            let hasMpowerInList = this.props.selectedBillAccountDetails.isPrePay ? true : checkForMpowerInBillAccountList(this.props.billAccountList)
            this.setState({
                hasMpower: hasMpowerInList
            })
        }
        else {
            updateDataIfNeeded(this.props)
        }
    }

    componentDidUpdate(prevProps){
        if (hasLoaded(this.props)) {
            this.props.getCreditCardPaymentLink()

            /* eslint react/no-did-update-set-state: "off" */
            /* It's ok to call setState in componentDidUpdate. See https://reactjs.org/docs/react-component.html . */
            if (this.props.billAccountList !== prevProps.billAccountList) {
                let hasMpowerInList = this.props.selectedBillAccountDetails.isPrePay ? true : checkForMpowerInBillAccountList(this.props.billAccountList)
                this.setState({
                    hasMpower: hasMpowerInList
                })
            }
        }
        else {
            updateDataIfNeeded(this.props)
        }
    }

    render() {
        const isSpanish = (this.props.i18n.language === 'es')

        if (!hasLoaded(this.props) && !hasLoadedCreditCardPaymentUrl(this.props)) {
            return (<div>
                    <label className="h5">{this.props.t("Other payment options")}</label>
                    <Card className="px-1 py-4">
                        <CircularProgress size={20} thickness={5} style={{ marginLeft: "45%", marginTop: "10%", marginBottom: "10%"}} />
                    </Card>
                </div>
            )
        }
        else {
            return (<div>
                <label className="h5">{this.props.t("Other payment options")}</label>
                    <Card className="px-1 py-4">
                        <ul className="mb-0">
                            {this.state.showGiftPayment &&
                            <li>
                                <Link to="/myaccount/payment/gift">{this.props.t("Make a one-time gift payment")}</Link>
                            </li>
                            }
                            {this.state.showCreditDebit &&
                            <li>
                                <MakeCreditCardPaymentLink
                                    creditCardPaymentUrl={this.props.creditCardPaymentUrl}
                                    multiPayment={this.state.multiPayment}
                                    hasMpower={this.state.hasMpower}
                                />
                            </li>
                            }
                            {this.state.showAutoPay &&
                            <li>
                                <Link to="/myaccount/payment/autopay">{this.props.t("Set up SurePay")}</Link>
                            </li>
                            }
                            {this.state.showPayCenterLink &&
                            <li>
                                <Link to="/myaccount/paymentlocations">{this.props.t("Find an In-person payment location")}</Link>
                            </li>
                            }

                            <li>
                                <a href={isSpanish
                                    ? `${config.srpnetBaseUrlEs}cuenta/electricidad/manejar-factura/pago-factura-electrica`
                                    : `${config.srpnetBaseUrl}account/electric/manage-bill/pay-electric-bill`}>{this.props.t("More payment methods")}</a>
                            </li>
                        </ul>
                    </Card>
                </div>
            )
        }
    }
}


OtherPaymentOptions.propTypes = {
    showPayCenterLink: PropTypes.bool,
    showGiftPayment: PropTypes.bool,
    showCreditDebit: PropTypes.bool,
    showAutoPay: PropTypes.bool,
    multiPayment: PropTypes.bool,
    getCreditCardPaymentLink: PropTypes.func.isRequired,
    getContacts: PropTypes.func.isRequired,
    getMailingAddress: PropTypes.func.isRequired,
    creditCardPaymentUrl: PropTypes.string.isRequired,
    selectedBillAccount: PropTypes.number.isRequired,
    billAccountList: PropTypes.arrayOf(PropTypes.object),
    billAccountListStatus: PropTypes.string,
    billingAddressStatus: PropTypes.string,
    rateMetaDataStatus: PropTypes.string,
    paymentInfoListStatus: PropTypes.string,
    contactsStatus: PropTypes.string,
    serviceAddressStatus: PropTypes.string,
    selectedBillAccountDetails: PropTypes.object,
    i18n: PropTypes.shape({
        language: PropTypes.string.isRequired,
    }).isRequired,
    t: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
    return {
        billAccountListStatus: state.accountInfo.billAccount.billAccountListStatus,
        billingAddressStatus: state.accountInfo.billAccount.billingAddressStatus,
        rateMetaDataStatus: state.rateMetaData.rateMetaDataStatus,
        paymentInfoListStatus: state.multiPayment.paymentInfoListStatus,
        contactsStatus: state.accountInfo.contact.contactsStatus,
        serviceAddressStatus: state.accountInfo.billAccount.serviceAddressStatus,
        creditCardPaymentUrlStatus: state.accountInfo.payment.creditCardPaymentUrlStatus,
        creditCardPaymentUrl: state.accountInfo.payment.creditCardPaymentUrl,
        selectedBillAccount: state.accountInfo.billAccount.selectedBillAccount,
        selectedBillAccountDetails: state.accountInfo.billAccount.selectedBillAccountDetails,
        billAccountList: state.accountInfo.billAccount.billAccountList
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getCreditCardPaymentLink: () => {
            dispatch(getCreditCardMultiPaymentUrl())
        },
        getContacts: (billAccount) => {
            dispatch(getContacts(billAccount))
        },
        getMailingAddress: (billAccount) => {
            dispatch(getBillingAddress(billAccount))
        }
    }
}

function checkForMpowerInBillAccountList(billAccountList) {
    let hasMpower = false
    billAccountList.forEach(billAccount => {
        if (billAccount.isPrePay) {
            hasMpower = true
        }
    })

    return hasMpower
}

export default withTranslation("otherPaymentOptions")(connect(mapStateToProps, mapDispatchToProps)(OtherPaymentOptions))